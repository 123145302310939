import { api } from "@/apps/common/api-client";
import { MEMBER_SERVICE_STATUS } from "@/apps/common/constants";
import { MemberServiceType } from "@/apps/common/types/appointment-types";
import { useRequest } from "ahooks";
import { useEffect, useMemo, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import {
  MemberServiceContext,
  MemberServiceContextType
} from "../../../common/components/contexts/MemberServiceContext";
import Appointment from "../../components/Appointment";
import CalendarApp from "../../components/Calendar";
import MemberProfileCompletion from "../../components/MemberProfileCompletion";
import Questionnaire from "../../components/Questionnaire";
import ServiceTypeSelection from "../../components/ServiceTypeSelection";
import classes from "./Dashboard.module.css";
import ServiceSelector from "./ServiceSelector";
import { STATUSES_TO_SCHEDULE_APPOINTMENT } from "@/apps/common/constants";
import { useCommonStore } from "@/apps/common/store/useCommonStore";

function getComponentForStatus(
  memberService: MemberServiceType,
  status?: string
) {
  status = status ?? memberService.status;

  if (!status) {
    return undefined;
  }

  switch (status) {
    case MEMBER_SERVICE_STATUS.SIGNED_UP:
    case MEMBER_SERVICE_STATUS.REFERRAL_SENT:
    case MEMBER_SERVICE_STATUS.REPEAT_REFERRAL_SENT:
      return memberService.user?.firstName === "."
        ? MemberProfileCompletion
        : ServiceTypeSelection;

    case MEMBER_SERVICE_STATUS.ONBOARDED:
    case MEMBER_SERVICE_STATUS.SURVEY_STARTED:
      return Questionnaire;

    case MEMBER_SERVICE_STATUS.SURVEY_COMPLETED:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_DECLINED:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_RESCHEDULE:
      return CalendarApp;

    case MEMBER_SERVICE_STATUS.APPOINTMENT_NO_SHOW:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_PAYMENT_FAILED:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_SCHEDULED:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_COMPLETE:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_STATUS_PENDING:
    case MEMBER_SERVICE_STATUS.APPOINTMENT_PAYMENT_PENDING:
    case MEMBER_SERVICE_STATUS.REPORT_SUBMITTED:
    case MEMBER_SERVICE_STATUS.REPORT_PAYMENT_PENDING:
    case MEMBER_SERVICE_STATUS.REPORT_GENERATION_STARTED:
    case MEMBER_SERVICE_STATUS.REPORT_GENERATION_COMPLETED:
    case MEMBER_SERVICE_STATUS.REPORT_GENERATION_SENT:
    case MEMBER_SERVICE_STATUS.COMPLETE:
    case MEMBER_SERVICE_STATUS.CANCELED:
    case MEMBER_SERVICE_STATUS.ARCHIVED:
      return Appointment;
  }
}

function ServiceStep({
  memberService,
  refreshMemberService,
  loading
}: MemberServiceContextType & {
  loading: boolean;
}) {
  const [overrideStatus, setOverrideStatus] = useState<string>();

  const DashboardComponent = useMemo(() => {
    return getComponentForStatus(memberService, overrideStatus);
  }, [memberService, overrideStatus]);

  useEffect(() => {
    setOverrideStatus(undefined);
  }, [memberService]);

  return (
    <MemberServiceContext.Provider
      value={{ refreshMemberService, memberService }}
    >
      <div>
        {DashboardComponent ? (
          <DashboardComponent setOverrideStatus={setOverrideStatus} />
        ) : null}

        {loading ? (
          <div className={classes.spinnerOverlay}>
            <Spinner
              style={{ width: 100, height: 100 }}
              animation="border"
              variant="info"
            />
          </div>
        ) : null}
      </div>
    </MemberServiceContext.Provider>
  );
}

export default function MemberDashboard() {
  const {
    data: memberServices,
    loading,
    runAsync: refreshMemberService
  } = useRequest(() => api.memberServices.findMany());

  const [searchParams, setSearchParams] = useSearchParams();
  const memberServiceId = searchParams.get("memberServiceId");

  const { setScheduleAppointmentCheck } = useCommonStore();

  useEffect(() => {
    if (!memberServices) {
      return;
    }

    if (memberServices.length) {
      const filteredAppointments = memberServices.filter(
        (memberService) =>
          !STATUSES_TO_SCHEDULE_APPOINTMENT.includes(memberService.status)
      );
      if (filteredAppointments.length) {
        setScheduleAppointmentCheck(true);
      }
    }

    if (memberServiceId) {
      return;
    }

    if (memberServices.length === 1) {
      setSearchParams({ memberServiceId: String(memberServices[0].id) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberServiceId, memberServices]);

  const activeMemberService = useMemo(
    () =>
      memberServices?.find(
        (memberService) => memberService.id === Number(memberServiceId)
      ),
    [memberServiceId, memberServices]
  );

  useEffect(() => {
    if (memberServiceId && memberServices && !activeMemberService && !loading) {
      refreshMemberService();
    }
  }, [
    memberServiceId,
    memberServices,
    activeMemberService,
    loading,
    refreshMemberService
  ]);

  if (!memberServices || (memberServiceId && !activeMemberService)) {
    return <Spinner animation="border" />;
  }

  if (!memberServiceId) {
    return (
      <ServiceSelector
        memberServices={memberServices}
        onChange={(memberServiceId) =>
          setSearchParams({ memberServiceId: String(memberServiceId) })
        }
      />
    );
  }

  return (
    <>
    <script src="https://www.dwin1.com/108440.js" type="text/javascript" defer={true}></script>
    <div>
      {activeMemberService ? (
        <ServiceStep
          memberService={activeMemberService}
          refreshMemberService={refreshMemberService}
          loading={loading}
        />
      ) : null}
    </div>
    </>
  );
}
