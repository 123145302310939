import { yupResolver } from "@hookform/resolvers/yup";
import FeatherIcons from "feather-icons-react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useLocation, useSearchParams } from "react-router-dom";
import * as yup from "yup";
//import { StateType } from "../../store/useAuthStore";
// components
import { FormInput, VerticalForm } from "@/apps/common/components/";

import { useTitle } from "@/apps/common/helpers/useTitle";
import AuthLayout from "@/apps/common/pages/auth/AuthLayout";
import { useAuthStore } from "@/apps/common/store/useAuthStore";
import { useEffect, useState } from "react";
import { getUserTimezone } from "@/apps/common/helpers/date";

// images
type _state = {
  id: number;
  attributes: {
    name: string;
    code: string;
    createdAt: string;
    updatedAt: string;
    enabled: boolean;
  };
};

interface UserAuthData {
  email: string;
  password: string;
}

/* bottom links */
const BottomLink = () => {
  const { t } = useTranslation();

  return (
    <Row className="mt-3">
      <Col xs={12} className="text-center">
        <p className="text-muted">
          {t("Already have account?")}{" "}
          <Link to={"/auth/login"} className="text-primary fw-bold ms-1">
            {t("Login")}
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Register = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const token = searchParams.get("token");
  const { t } = useTranslation();
  const { fetchStates, register, user, loggedIn } = useAuthStore();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useTitle("Register");

  const authSchemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter Email")
        .email("Please enter valid Email"),
      password: yup.string().required(t("Please enter Password")),
      checkboxsignup: yup.bool().oneOf([true])
    })
  );

  const onSubmitAuth = async (formData: UserAuthData) => {
    try {
      setLoading(true);

      await register({
        payload: {
          username: formData.email,
          ...formData,
          ...(token && { token }),
          timezone: getUserTimezone()
        }
      });

      setLoading(false);
    } catch (err) {
      const errResponse = (
        err as { response?: { data?: { error?: { message?: string } } } }
      ).response;
      let errMessage = errResponse?.data?.error?.message || "";
      if (errMessage === "Email or Username are already taken") {
        errMessage = t("This email account already exists.");
      }
      setError(errMessage);
      console.log("err", err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  const redirectUrl = location?.search?.slice(6) || "/";

  return (
    <>
      <script src="https://www.dwin1.com/108440.js" type="text/javascript" defer={true}></script>
      {loggedIn || user ? <Navigate to={redirectUrl}></Navigate> : null}

      <AuthLayout bottomLinks={<BottomLink />}>
        <h4 className="h4 mb-3 mt-2">{t("Create your account")}</h4>

        {error && (
          <Alert variant="danger" className="my-2">
            {error}
          </Alert>
        )}

        <VerticalForm<UserAuthData>
          onSubmit={onSubmitAuth}
          resolver={authSchemaResolver}
          defaultValues={{}}
          formClass="authentication-form row"
        >
          <FormInput
            label={t("Email Address")}
            type="email"
            name="email"
            startIcon={<FeatherIcons icon={"mail"} className="icon-dual" />}
            placeholder={t("hello@domain.com")}
            containerClass={"mb-2 col-12"}
          />
          <FormInput
            label={t("Password")}
            type="password"
            name="password"
            startIcon={<FeatherIcons icon={"lock"} className="icon-dual" />}
            placeholder={t("Enter your Password")}
            containerClass={"mb-3 col-12"}
          />
          <FormInput
            element={
              <span className="fs-10">
                Please check this box to acknowledge that you have read and
                accept the{" "}
                <a
                  href="https://skylerhealth.com/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                and the{" "}
                <a href="https://skylerhealth.com/tou" target="_blank">
                  Terms of Use
                </a>
              </span>
            }
            type="checkbox"
            name="checkboxsignup"
            containerClass={"mb-2 col-12"}
          />
          <div className="mb-2 text-center d-grid col-12">
            <Button type="submit" disabled={loading}>
              {loading ? t("Loading...") : t("Sign Up")}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default Register;
